<template>
  <div class="interest">
    <downBtn
      :topShow="true"
      :isWx="isWx"
      @down="download"
      @wechatShow="wechatShow"
    ></downBtn>
    <div>
      <video
        :src="list[0].detailList[0].video"
        controls
        :poster="
          list[0].detailList[0].video +
          `?x-oss-process=video/snapshot,t_3000,m_fast`
        "
        ref="refVideo"
        id="upvideo"
        controlslist="nodownload"
        x-webkit-airplay="allow"
        webkit-playsinline="true"
        x5-playsinline="true"
        x5-video-player-type="h5"
        x5-video-player-fullscreen="false"
        playsinline="true"
        preload="auto"
        x5-video-orientation="h5"
      >
        此浏览器不支持video标签
      </video>
    </div>

    <div class="interest_box">
      <div class="interest_item">
        <div class="interest_left">
          <div class="left_1">
            <div class="left_img">
              <img src="@/assets/Vector (5).png" alt="" />
              {{list[0].title}}
            </div>
            <div class="left_size">
              <span>相关课程</span>
              <span>TOPLK能力考</span>
            </div>
          </div>
          <div class="left_2"></div>
        </div>
        <div class="interest_rgith">
          <div class="interest_r1">
            <img src="@/assets/Group 427318277.png" alt="">
            <div>咨询</div>
          </div>
        </div>
      </div>
    </div>

    <div class="curriculum_box">
      <div class="curriculum_tab">相关课程</div>
      <div
        class="curriculum_list"
        v-for="(item, index) in list[0].detailList"
        :key="index"
      >
        <div class="curriculum_1">
          <video
            :src="item.video"
            controls
            :poster="item.video + `?x-oss-process=video/snapshot,t_3000,m_fast`"
            ref="refVideo"
            id="upvideo"
            controlslist="nodownload"
            x-webkit-airplay="allow"
            webkit-playsinline="true"
            x5-playsinline="true"
            x5-video-player-type="h5"
            x5-video-player-fullscreen="false"
            playsinline="true"
            preload="auto"
            x5-video-orientation="h5"
          >
            此浏览器不支持video标签
          </video>
          <div></div>
        </div>
        <div class="curriculum_2">
          <p>{{item.title}}</p>
          <div>
            <img src="@/assets/Group 956.png" alt="" />
            <span>{{item.time}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import downBtn from "@/components/downBtn.vue";
import mixin from "@/utils/mixin";
import { InterestData } from "@/api/teacher";
export default {
  mixins: [mixin],
  name: "interest",
  components: {
    downBtn,
  },
  props: {},
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.InterestData();
  },
  methods: {
    async InterestData() {
      const data = await InterestData({ application: 2 });
      console.log(data);
      this.list = data.data.filter(
        (item) => item.id == (this.$route.query.id || 5)
      );
      console.log(this.list , 45415641);
    },
  },
};
</script>

<style scoped lang="scss">
.interest {
}
video {
  margin: 37px 0 0;
  height: 233px;
  width: 100%;
  object-fit: fill;
}
.interest_box {
  padding: 0px 16px;

  .interest_item {
    height: 92px;
    background: #fff1f1;
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .interest_left {
      .left_1 {
        .left_img {
          padding: 21px 0 0 18px;
          img {
            width: 15px;
            height: 16px;
          }
          font-size: 18px;
          color: #333333;
          font-weight: 500;
          font-size: 18px;
        }
        .left_size {
          padding: 0px 0px 18px 18px;
          span {
            font-size: 12px;
            &:nth-child(1) {
              background: #ff7777;
              padding: 2px;
              color: #ffffff;
              margin-right: 9px;
              border-radius: 3px;
            }
          }
        }
      }
    }
    .interest_rgith {
      margin-right: 37px;
      .interest_r1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          width: 35px;
          height: 35px;
          margin-bottom: 5px;
        }
        font-size: 12px;
        color: #666666;
      }
    }
  }
}

.curriculum_box {
  padding: 0px 16px;
  margin-top: 21px;
  .curriculum_tab {
    font-size: 18px;
    color: #333333;
    font-weight: 500;
  }
  .curriculum_list {
    display: flex;
    width: 100%;
    margin-top: 10px;
    .curriculum_1 {
      width: 140px;
      height: 90px;
      position: relative;
      border-radius: 12px;
      margin-right: 20px;
      video {
        width: 140px;
        height: 90px;
        margin: 0px;
      }
      div {
        width: 140px;
        height: 90px;
        position: absolute;
        top: 0px;
        left: 0px;
        background: transparent;
        z-index: 99;
      }
    }
    .curriculum_2 {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
      p {
        font-size: 16px;
        color: #333333;
        line-height: 20px;
      }
      div {
        display: flex;
        align-items: center;
        img {
          width: 21px;
          height: 21px;
          margin-right: 5px;
        }
        span {
          font-size: 14px;
          color: #666666;
        }
      }
    }
  }
}
</style>
